import React, { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Appbar from "../Header/Appbar";
import "./resources.css";
import Sidebar from "../Sidebar/Sidebar";
import { Button, Typography, CircularProgress } from "@mui/material";
import MonthHeader from "./MonthHeader";
import axios from "axios";
import EventBody from "./EventBody";

function Resources() {
  const { user } = useAuthenticator();
  const award_opportunity_url =
    "https://docs.google.com/spreadsheets/d/16LvoftW4ApR7KZgkOJ3xofn591iPOIw68b0PWpX6zd0/edit?usp=sharing";
  const ecosystem_hub_url = "https://wp.nyu.edu/ecosystem_hub/";
  const [loading, setLoading] = useState(true);
  const [allEvents, setAllEvents] = useState([{}]);
  const num_to_month = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
  }

  // const all_events = [
  //   {
  //     month: "September",
  //     year: "2024",
  //     events: [
  //       {
  //         date: "23",
  //         day: "Mon",
  //         month: "September",
  //         year: "2024",
  //         time: "5:00 pm - 7:00 pm",
  //         eventLink:
  //           "https://wp.nyu.edu/ecosystem_hub/event/new-york-nanoscience-discussion-group-september-2024/",
  //         location: "",
  //         title: "New York Nanoscience Discussion Group September 2024",
  //         desc: "An American Chemical Society New York Section Topical Group hosted by The New York University's Department of Chemistry, the Molecular Design Institute & NanoBioX and the CUNY Advanced Science Research Center Refershments at 5:00; Science at 5:30",
  //         image:
  //           "https://wp.nyu.edu/ecosystem_hub/files/2024/09/Screenshot-2024-09-16-141822.png",
  //       },
  //     ],
  //   },
  //   {
  //     month: "October",
  //     year: "2024",
  //     events: [
  //       {
  //         date: "2",
  //         day: "Wed",
  //         month: "October",
  //         year: "2024",
  //         time: "9:00 am - 10:30 am",
  //         eventLink:
  //           "https://wp.nyu.edu/ecosystem_hub/event/effective-leadership-exploring-context-and-role/",
  //         location: "",
  //         title: "Effective Leadership: Exploring Context and Role",
  //         desc: "This interactive session of Ecosystem Effective Leadership program sets the frame for further sessions by focusing on what it takes to be effective as a leader.  Leadership is an activity not necessarily an attribute of an individual.  Ellen Schall, who has both studied and practiced leadership in multiple roles and contexts will help the group to explore how one might take up the work of leadership - and how each of us can begin to build a theory of how we can lead.",
  //         image:
  //           "https://wp.nyu.edu/ecosystem_hub/files/2024/07/Effective-Leadership_Session-1.jpg",
  //       },
  //       {
  //         date: "3",
  //         day: "Thu",
  //         month: "October",
  //         year: "2024",
  //         time: "4:30 pm - 5:30 pm",
  //         eventLink:
  //           "https://wp.nyu.edu/ecosystem_hub/event/keynote-speaker-series-jennifer-doudna/",
  //         location:
  //           "Greenberg Lounge 40 Washington Square S, New York, NY, United States",
  //         title: "Keynote Speaker Series: Jennifer Doudna",
  //         desc: "Join us for a Keynote session by Dr. Jennifer Doudna, who received a Nobel Prize in Chemistry for her pioneering work in CRSIPR gene editing. Dr. Doudna will discuss CRISPR and the scientific and societal advances that will expand both the applications and impact of genome editing across the globe.",
  //         image: "https://wp.nyu.edu/ecosystem_hub/files/2024/08/Poster-1.jpg",
  //       },
  //     ],
  //   },
  // ];

  useEffect(() => {
    if (user && user.attributes){
      axios
        .get(`${process.env.REACT_APP_BACKEND_URI}/events/`)
        .then((res) => {
          setAllEvents(res.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("There was an error to load the events", error);
        });

    }
  }, [user]);

  return (
    <div className="resources">
      <Appbar />
      {loading ? (
        <div className="loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="resource__body">
          <div className="sidebar">
            <Sidebar />
            <Button
              className="resources-btn"
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => window.open(ecosystem_hub_url, "_blank")}
            >
              NYU Ecosystem Hub
            </Button>
          </div>
          <div className="links__body">
            <Typography
              variant="h4"
              component="h1"
              sx={{ fontWeight: 600, mt: 3, mb: 6 }}
              className="heading"
            >
              Upcoming Events
            </Typography>
            {allEvents.map((eventsList, idx) => (
              <div key={idx}>
                <MonthHeader month={num_to_month[eventsList.month]} year={eventsList.year} />
                {eventsList.events.map((event, index) => (
                  <EventBody key={index} eventDetails={event}></EventBody>
                ))}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Resources;
