import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import axios from "axios";
import { useAuthenticator } from "@aws-amplify/ui-react";
import SendIcon from "@mui/icons-material/Send";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Comment from './Comment'

const PostComponent = ({ post, refresh, setRefresh }) => {
  const { user } = useAuthenticator();
  const [postAnchorEl, setPostAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newComment, setNewComment] = useState("");

  const handleCommentSubmit = () => {
    const toISOStringWithTimezone = (date) => {
      const tzOffset = -date.getTimezoneOffset();
      const diff = tzOffset >= 0 ? "+" : "-";
      const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, "0");
      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        diff +
        pad(tzOffset / 60) +
        ":" +
        pad(tzOffset % 60)
      );
    };
    const formattedTimestamp = toISOStringWithTimezone(new Date());
    axios.post(`${process.env.REACT_APP_BACKEND_URI}/comments/`, {
      menteeId: user.attributes.email,
      postId: post.id,
      content: newComment,
      timestamp: formattedTimestamp,
    });
    setNewComment("");
    setRefresh(!refresh);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPost = (event) => {
    setPostAnchorEl(event.currentTarget);
  };

  const handleClosePost = () => {
    setPostAnchorEl(null);
  };

  const handlePostDelete = async (e) => {
    // Implement your delete logic here
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URI}/posts/${post.id}`)
      .then((res) => console.log(res.data))
      .finally(() => {
        setRefresh(!refresh);
      });
    handleClose();
  };

  const handleCommentDelete = async (id) => {
    // Implement your delete logic here
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URI}/comments/${id}`)
      .then((res) => console.log(res.data))
      .finally(() => {
        setRefresh(!refresh);
      });
    handleClose();
  };

  return (
    <Card variant="outlined" style={{ margin: "16px 0" }}>
      <CardContent>
        <div className="post-mentee-details">
          <AccountCircleIcon></AccountCircleIcon>
          <Typography className="mentee-name">{post.menteeName}</Typography>
          <Typography className="mentee-name" style={{ marginLeft: "1rem" }}>
            {post.timestamp}
          </Typography>
          <IconButton
            color="primary"
            style={{
              marginLeft: "auto",
              alignItems: "center",
              marginRight: "1rem",
            }}
            onClick={handleClickPost}
          >
            {user.attributes.email === post.menteeId ? (
              <MoreHorizIcon style={{ color: "black" }} />
            ) : null}
          </IconButton>

          <Menu
            anchorEl={postAnchorEl}
            open={Boolean(postAnchorEl)}
            onClose={handleClosePost}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem onClick={handlePostDelete}>Delete</MenuItem>
          </Menu>
        </div>
        <Typography className="post-title">{post.title}</Typography>
        <Typography className="post-content">{post.content}</Typography>

        <form style={{ marginTop: "16px" }}>
          <TextField
            label="Add Comment"
            variant="outlined"
            fullWidth
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton color="primary" onClick={handleCommentSubmit}>
                    <SendIcon style={{ color: "#57068c" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>

        <div style={{ marginTop: "16px" }}>
          {post.comments.map((comment) => (
            <Comment comment={comment} refresh={refresh} setRefresh={setRefresh}/>
            // <div key={comment.id} className="comment">
            //   <div
            //     className="post-mentee-details"
            //     style={{ marginBottom: "0rem" }}
            //   >
            //     <AccountCircleIcon></AccountCircleIcon>
            //     <div className="name-conente">
            //       <Typography className="mentee-name">
            //         {comment.menteeName}
            //       </Typography>
            //       <Typography>{comment.content}</Typography>
            //     </div>
            //     <Typography>{comment.id}</Typography>

            //     <IconButton
            //       color="primary"
            //       style={{
            //         marginLeft: "auto",
            //         alignItems: "center",
            //         marginRight: "1rem",
            //       }}
            //       onClick={handleClick}
            //     >
            //       {user.attributes.email === comment.menteeId ? (
            //         <MoreHorizIcon style={{ color: "black" }} />
            //       ) : null}
            //     </IconButton>
            //     {/* <Typography>{comment.id}</Typography> */}

            //     <Menu
            //       anchorEl={anchorEl}
            //       open={Boolean(anchorEl)}
            //       onClose={handleClose}
            //       anchorOrigin={{ vertical: "top", horizontal: "right" }}
            //       transformOrigin={{ vertical: "top", horizontal: "right" }}
            //     >
            //       <MenuItem onClick={() => handleCommentDelete(comment.id)}>
            //         Delete {comment.id}
            //       </MenuItem>
            //     </Menu>
            //   </div>
            // </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

export default PostComponent;
