import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  LinearProgress,
  Typography,
} from "@mui/material";
import Sidebar from "../Sidebar/Sidebar";
import "./profile.css";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import AddTaskForm from "./AddTaskForm";
import Appbar from "../Header/Appbar";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

function Profile() {
  const { user } = useAuthenticator();
  const [goalsList, setGoalsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(true);
  const [heading, setHeading] = useState("");
  const navigate = useNavigate();
  const subHeadings = ["Grants", "Publications", "Teaching"];
  const [completionPercentage, setCompletionPercentage] = useState({
    Grants: 0,
    Publications: 0,
    Teaching: 0,
  });

  const calculateProgress = () => {
    // goalsList.map((taskList) => {
    //   taskList.goals.map((task) => {
    //     console.log(task.goal, task.checked);
    //   })
    // })
    const completedCount =
      goalsList == null
        ? []
        : goalsList.map((section) => {
            const completedGoals = section.goals.filter((goal) => goal.checked);
            return {
              id: section._id,
              completed: completedGoals.length,
              total: section.goals.length,
            };
          });

    const percentage = {};
    completedCount.forEach((task) => {
      percentage[task.id.toString()] = (task.completed / task.total) * 100;
    });
    setCompletionPercentage(percentage);
  };

  const handleChange = (id, checked) => {
    axios
      .put(`${process.env.REACT_APP_BACKEND_URI}/goals/${id}`, {
        checked: !checked,
      })
      .then((res) => {
        setRefresh(!refresh);
      })
      .catch((error) => {
        console.error("This is error", error);
      });
  };

  const handleDelete = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BACKEND_URI}/goals/${id}`)
      .then((res) => {
        setRefresh(!refresh);
      });
  };

  useEffect(() => {
    // if (user === undefined) {
    //   navigate("/login");
    // }
    if (user && user.attributes) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URI}/goals/${user.attributes.email}`
        )
        .then((res) => {
          setGoalsList(res.data);
          calculateProgress();
        })
        .catch((error) => {
          console.error("This is error", error);
        });
    }
  }, [user, open, refresh]);

  useEffect(() => {
    calculateProgress(); // Recalculate progress whenever goalsList changes
  }, [goalsList]);

  return (
    <div className="profile">
      <Appbar />
      <AddTaskForm
        open={open}
        setOpen={setOpen}
        heading={heading}
        email={user && user.attributes ? user.attributes.email : ""}
      />
      <div className="profile__body">
        {user ? (
          <div className="sidebar">
            <Sidebar />
          </div>
        ) : null}
        <div className="goals__body">
          <Typography variant="h4" component="h1" sx={{ fontWeight: 600 }}>
            My Goals
          </Typography>
          <ul className="list">
            {subHeadings.map((topic, index) => (
              <li key={index}>
                <div className="goals__topic">
                  <Typography
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: 500 }}
                  >
                    {topic}
                  </Typography>
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ backgroundColor: "#8540b3", height: "25px" }}
                    onClick={() => {
                      setOpen(true);
                      setHeading(topic);
                    }}
                  >
                    Add
                  </Button>
                </div>
                {goalsList == null ? (
                  <Typography sx={{ marginTop: "1rem" }}>
                    No goals added
                  </Typography>
                ) : (
                  <div>
                    {goalsList.filter(
                      (goals_subList) => goals_subList._id === topic
                    ).length === 0 ? (
                      <Typography sx={{ marginTop: "1rem" }}>
                        No goals added
                      </Typography>
                    ) : (
                      goalsList
                        .filter((goals_subList) => goals_subList._id === topic)
                        .map((goals_subList, idx) => (
                          <div className="goals-list" key={idx}>
                            {goals_subList.goals.map((goal, goalIdx) => (
                              <div key={goalIdx} className="goals">
                                <Checkbox
                                  checked={goal.checked}
                                  onChange={() =>
                                    handleChange(goal._id, goal.checked)
                                  }
                                  // inputProps={{ "aria-label": "controlled" }}
                                />
                                <Typography
                                  sx={{
                                    fontWeight: 500,
                                    fontSize: "17px",
                                    textDecoration: goal.checked
                                      ? "line-through"
                                      : "none",
                                  }}
                                >
                                  {goal.goal}
                                </Typography>
                                <IconButton
                                  size="large"
                                  color="black"
                                  sx={{ marginLeft: "auto" }}
                                  onClick={() => handleDelete(goal._id)}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </IconButton>
                              </div>
                            ))}
                            <div className="progress">
                              <Typography>Progress:</Typography>
                              <Box sx={{ width: "15rem" }}>
                                <LinearProgress
                                  variant="determinate"
                                  value={completionPercentage[topic] || 0}
                                  className="progress-bar"
                                />
                              </Box>
                              <Typography variant="body2">
                                {Math.ceil(completionPercentage[topic])}%
                              </Typography>
                            </div>
                          </div>
                        ))
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Profile;
