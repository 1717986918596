import React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Overview from "../Overview/Overview";
import Sidebar from "../Sidebar/Sidebar";
import "./sidebarWithOverview.css";

function SidebarWithOverview({ overviewTopics }) {
  const { user } = useAuthenticator();

  return (
    <>
      {user ? (
        <div className="overview_with_sidebar">
          <div className="overview_with_sidebar_top">
            {/* <div className="sidebar"> */}
            <Sidebar />
            {/* </div> */}
            <Overview overviewTopics={overviewTopics} />
          </div>
        </div>
      ) : (
        <div className="overview_with_sidebar">
          <Overview overviewTopics={overviewTopics} />
        </div>
      )}
    </>
  );
}

export default SidebarWithOverview;
